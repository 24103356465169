import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Navigation from './Navigation'
import Categorias from './Categorias'
import Articulos from './Articulos'
import './App.css';

function App() {
  return (
    <Router>
      <Navigation />
      <div className="container p-4">
        <Route path="/" exact component={Categorias} />
        <Route path="/categorias" exact component={Categorias} />
        <Route path="/articulos/:categoriaid" exact component={Articulos} />
      </div>
    </Router>
  );
}

export default App;

