import React,{Component} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Articulos.css';
import {apires} from './Servicios/Apirest';
import axios from 'axios';




class Articulos  extends Component{
  
    constructor(props) {
        super(props);
        this.state = {
            articulos: [],
            number: 0
        };
        
    }

       
    handleChange = event => {
        this.setState({
          number: event.target.value > 999999999 ? 999999999 : event.target.value,
        });
      };
    
    async componentDidMount() {
        
        let url = apires+"articulos?page=$1";
        //let url = apires+"articulos?id=1";
        axios.get(url)
        .then(response=>{
            console.log(response);
            this.setState({
                articulos: response.data,
            });
            }
        )
       
    }
    render(){
    const { match } = this.props   

    return (
        <div className="row">
           {this.state.articulos.map((value,index) =>
             {
              var numx = Number(value.valor).toLocaleString('en');   
              console.log(value.categoriaId);
              console.log(match.params.categoriaid);
              if (value.categoriaId === match.params.categoriaid)
                    {  
                        return(
                            <div className="card">
                                <img className="imgCategoria" src={'/img/'+value.imagen} alt="" ></img>
                                <div className="card-text">
                                    <h2>{value.nombre}</h2>
                                    <p>{value.descripcion}</p>
                                </div>
                                <div className="card-stats">
                                    <div className="stat">
                                       <div className="value">{value.tiempo}<sup>m</sup></div>
                                       <div className="type">Tiempo</div>
                                    </div>
                                    <div className="stat border">
                                      <div className="value">5.0</div>
                                      <div className="type">Calificacion</div>
                                    </div>
                                    <div className="stat">
                                       <div className="value">${numx}</div>
                                       <div className="type">Precio</div>
                                    </div>
                                </div>
                        </div>
                      )
                    } else {
                        if ( match.params.categoriaid == 0)
                            {  
                                return(
                                    <div className="card">
                                    <img className="imgCategoria" src={'/img/'+value.imagen} alt="" ></img>
                                    <div className="card-text">
                                        <h2>{value.nombre}</h2>
                                        <p>{value.descripcion}</p>
                                    </div>
                                    <div className="card-stats">
                                        <div className="stat">
                                        <div className="value">{value.tiempo}<sup>m</sup></div>
                                        <div className="type">Tiempo</div>
                                        </div>
                                        <div className="stat border">
                                        <div className="value">5.0</div>
                                        <div className="type">Calificacion</div>
                                        </div>
                                        <div className="stat">
                                        <div className="value">${numx}</div>
                                        <div className="type">Precio</div>
                                        </div>
                                    </div>
                            </div>
                                )
                            } 
                            
                    }
            }
           )
        }
      </div>
    )
}
}

export default Articulos

