import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from './assets/logo_01.png'
import './Navbar.css'


export default class Navigation extends Component {
    render() {
        return (
            <nav className="navbar">
                <a href="https://wa.me/573166538689?text=Me%20gustaría%20hacer%20un%20pedido" className="whatsapp" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>
                <div className="contenedor">
                    <Link to="/" className="imgCategoria" src={logo} alt="">
                       <img className="imgCategoria"  src={logo} alt="" ></img>
                    </Link>    
                <Link className="navbar-brand" to="/articulos/0" >
                     <button type="button" className="btn btn-warning btn-lg btn-block float-right">Menu 1.0</button>
                </Link>
                </div> 
            </nav>
        )
    }
}
