import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {apires} from './Servicios/Apirest';
import axios from 'axios';
import { Link } from 'react-router-dom'

class Categorias  extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            categorias: [],
            articulos: [],
            
        };
    }
    async componentDidMount() {
        let url_1 = apires+"categorias?page=$1";
        let url_2 = apires+"articulos?page=$1";
        axios.get(url_1)
        .then(response=>{
            console.log(response);
            this.setState({
                categorias: response.data,
            });
            }
        )
        axios.get(url_2)
        .then(response=>{
            console.log(response);
            this.setState({
                articulos: response.data,
            });
            }
        )

       
    }
    render(){
    return (
        <div className="row">
        {this.state.categorias.map((value,index) =>{
            let ArtCat = "/articulos/"+value.CategoriaId
            return(
                <Link to={ArtCat}>
                <div className="row">
                    <div className="card text-center text-dark" >
                        <img className="imgCategoria" src={'img/'+value.imagen} alt="Card image cap"></img>
                        <div className="card-body">
                            <h5 className="card-title" >{value.Nombre}</h5>
                            <p className="card-text" >{value.descripcion}</p>
                        </div>    
                    </div>
                 </div>    
                </Link>
            )
        })}
     
     <div className="row">
           {this.state.articulos.map((value,index) =>
             {
                var numx = Number(value.valor).toLocaleString('en');  
                return(
                            <div className="card">
                                <img className="imgCategoria" src={'/img/'+value.imagen} alt="" ></img>
                                <div className="card-text">
                                    <h2>{value.nombre}</h2>
                                    <p>{value.descripcion}</p>
                                </div>
                                <div className="card-stats">
                                    <div className="stat">
                                       <div className="value">{value.tiempo}<sup>m</sup></div>
                                       <div className="type">Tiempo</div>
                                    </div>
                                    <div className="stat border">
                                      <div className="value">5.0</div>
                                      <div className="type">Calificacion</div>
                                    </div>
                                    <div className="stat">
                                       <div className="value">${numx}</div>
                                       <div className="type">Precio</div>
                                    </div>
                                </div>
                        </div>
                      )
                    
                            
                    }
            
           )
        }
      </div>
      


      </div>
    )
}
}

export default Categorias

